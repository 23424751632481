import { externalFetch } from '@telekomconsalting/dex-guru-fetch'

import { Environment } from '../config/EnvironmentSettings'

export const newVersionAvailableCheck = async (currentVersion?: string): Promise<boolean> => {
  if (!currentVersion || process.env.REACT_APP_ENVIRONMENT === Environment.local) {
    return false
  }

  const latestVersion = await externalFetch<string>(`${window.location.origin}/version.txt`, {
    expectText: true,
  })
  return !!latestVersion && currentVersion !== latestVersion.replace(/\n/g, '')
}
