import BigNumber from 'bignumber.js'
import Web3 from 'web3'
import { Log } from 'web3-core'
import { AbiItem } from 'web3-utils'

import { Amounts, EthLog, StatefulTransaction, TokenV3 } from '../model'

const transferEventABI: AbiItem = {
  anonymous: false,
  inputs: [
    { indexed: true, name: 'from', type: 'address' },
    { indexed: true, name: 'to', type: 'address' },
    { indexed: false, name: 'value', type: 'uint256' },
  ],
  name: 'Transfer',
  type: 'event',
}
const TransferEventTopic0 = '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef' // keccak256 of Transfer(address,address,uint256)

/**
 *
 * @param estimated - what user sees in Verify Modal "Token To" field
 * @param actual - what token amount was transfered in txn
 * @param slippagePercent - what tip did user set (i.e. 0.5%)
 * @param tipsPercent - what tip did user set (i.e. 0.56%)
 * @returns Whether value in Transfer belongs to what we expect to recieve to user wallet
 *
 * *NOTE: All examples below are in human-readable forma (divided by 10**decimals)*
 *
 * ### Example with non-zero tip (https://bscscan.com/tx/0xd515ff405e06b175be3ebc5ad9baab38f05704cbaf194e1d42ead3f7cfd3cb09):
 * User submits txn to swap from 1 BUSD to 0.00234489464 BNB (`estimated`) with some 0.56 (`tipsPercent`) and 0.5% (`slippagePercent`),
 * there is a transfer event with 0.0023581 BNB (`actual`) to the contract which then creates 2 internal txns:
 * 1) `actual` * `tipsPercent` (0.0023581*0.0056) = 0.0000132053 BNB to affiliateAddress
 * 2) `actual` * (1 - `tipsPercent`) (0.0023581*(1-0.0056)) = 0.00234489464 BNB to the User
 *
 * So to check slippage we should compare `estimatedConsideringTips` (actually this is what user would see if they will set tip = 0 in verify modal) with `actual` from blockchain
 * */
const valueIsInSlippageRange = (
  estimated: BigNumber,
  actual: BigNumber,
  slippagePercent: number,
  tipsPercent: number
): boolean => {
  const estimatedConsideringTips = estimated.div(new BigNumber(1 - tipsPercent / 100))
  return estimatedConsideringTips
    .minus(actual)
    .abs()
    .div(estimatedConsideringTips)
    .times(100)
    .lte(new BigNumber(slippagePercent))
}

const equalAddresses = (emitterAddress?: string, addressToCompare?: string): boolean => {
  return emitterAddress?.toLowerCase() === addressToCompare?.toLowerCase()
}

function containsAnyFromArray<T>(sourceArray: T[], lookupArray: T[]): boolean {
  return sourceArray.some((x) => lookupArray.includes(x))
}

const containsAnyFromAddresses = (sourceAddresses: string[], lookupArray: string[]): boolean => {
  return containsAnyFromArray(
    sourceAddresses.map((x) => x?.toLowerCase()),
    lookupArray.map((x) => x?.toLowerCase())
  )
}

const getUserReceivedConsideringTips = (
  logtransferValue: number | string,
  tipsPercent: number,
  tipsTransfer?: EthLog
): BigNumber => {
  return tipsTransfer
    ? new BigNumber(logtransferValue).minus(new BigNumber(tipsTransfer.value)) // scenario when tip transfer is in Transfer logs https://ftmscan.com/tx/0xaa692d942ef179b11392826cc9a4baa97053c4d18cf2bd3fffa07e234ff27f82#eventlog
    : new BigNumber(logtransferValue).times(new BigNumber(1 - tipsPercent / 100)) // scenario when tip transfer is in internal txns https://bscscan.com/tx/0xd515ff405e06b175be3ebc5ad9baab38f05704cbaf194e1d42ead3f7cfd3cb09
}

/**
 * Calculates Post Swap Modal Token amounts
 * @param library = web3 lib
 * @param txn - txn with receipt and 0x quote
 * @param account - user wallet address
 * @param zeroXProxyAddress - 0x proxy (spender) address
 * @param tipsAddress - dex.guru affiliate (referrer) address
 * @param slippageFromSettings - sliappget percent (i.e. 0.5%)
 * @param tokenFrom - Sell Token
 * @param tokenTo  - Buy Token
 * @returns amounts user spent | received | tips paid in human-readable form (divided by 10**decimals)
 *
 * It is really pretty tricky stuff, there is no exact mechanism how to parse transfers from all chains | amms | contracts etc. so here is covered as many as possible to reproduce options how to trace transfers after txn was mined
 */
export const findAmounts = (
  library: Web3,
  txn: StatefulTransaction,
  account: string,
  zeroXProxyAddress: string,
  tipsAddress: string,
  slippageFromSettings: number,
  tokenFrom?: TokenV3,
  tokenTo?: TokenV3
): Amounts => {
  if (!txn.receipt?.logs?.length || !tokenFrom?.address || !tokenTo?.address) {
    return {}
  }
  const receipt = txn.receipt
  const slippage = txn.slippage || slippageFromSettings
  const estimatedAmountFrom = txn.quoteResponse && new BigNumber(txn.quoteResponse.sellAmount)
  const estimatedAmountTo = txn.quoteResponse && new BigNumber(txn.quoteResponse.buyAmount)

  const tipsPercent = txn.tip || 0

  // only events with signature `Transfer(address,address,uint256)` that was emitted by tokenFrom or tokenTo ERC20 contracts
  const transferEventsFromTradedTokens = receipt.logs.filter(
    (encodedLog) =>
      encodedLog.topics[0].toLowerCase() === TransferEventTopic0 &&
      ((tokenFrom.address && equalAddresses(encodedLog.address, tokenFrom.address)) ||
        (tokenTo.address && equalAddresses(encodedLog.address, tokenTo.address)))
  )

  // decoded Transfer logs
  const transfers = transferEventsFromTradedTokens.map((encodedLog) => ({
    decodedLog: readLogs(library, encodedLog, transferEventABI),
    encodedLog,
  }))

  // exact match transfer of tokenTo to affiliate address  i.e. USDC log #21 here https://ftmscan.com/tx/0xaa692d942ef179b11392826cc9a4baa97053c4d18cf2bd3fffa07e234ff27f82#eventlog
  const transfersToAffiliateAddress = transfers.filter(
    (x) =>
      equalAddresses(x.encodedLog.address, tokenTo.address) &&
      containsAnyFromAddresses([x.decodedLog.to], [tipsAddress])
  )

  // transfers emitted by tokenFrom ERC20 contract
  const tokenFromTransfers = transfers.filter((x) =>
    equalAddresses(x.encodedLog.address, tokenFrom.address)
  )

  // transfers emitted by tokenFrom ERC20 contract with exact match of either 0x address or user wallet i.e. USDC log #389  here https://etherscan.io/tx/0xc5eef56e54db16b3899a3e8abc221096e9fd8c7392e990b78c4f25dd4e800493#eventlog
  const tokenFromTransfersFromUserWalletOrZeroX = tokenFromTransfers.filter((x) =>
    containsAnyFromAddresses([x.decodedLog.from, x.decodedLog.to], [account, zeroXProxyAddress])
  )

  // sometimes From Amount is taken from Wrapped native Token contract i.e. WETH here https://etherscan.io/tx/0x9b9883b2d637127fa0881bb0b398d253b0aa75ae98d806845724afdc799e4c20#eventlog
  const tokenFromTransfersWithApproxTokenAmount = tokenFromTransfers.filter(
    (x) =>
      estimatedAmountFrom &&
      new BigNumber(estimatedAmountFrom).eq(new BigNumber(x.decodedLog.value))
  )

  const fromAmount = new BigNumber(
    tokenFromTransfersFromUserWalletOrZeroX[0]?.decodedLog.value || // main suggestion
      tokenFromTransfersWithApproxTokenAmount[0]?.decodedLog.value || // last hope gateway
      0 // fallback
  )

  // transfers emitted by tokenTo ERC20 contract
  const tokenToTransfers = transfers.filter((x) =>
    equalAddresses(x.encodedLog.address, tokenTo.address)
  )

  // transfers emitted by tokenFrom ERC20 contract with exact match of either 0x address or user wallet i.e. USDC log #146 here https://polygonscan.com/tx/0x7ac39183848bbed3fe177cb4954bb4c4fe6335cf5236490f87628229a37f8c2b#eventlog
  const tokenToTransfersToUserWalletOrZeroX = tokenToTransfers.filter((x) =>
    containsAnyFromAddresses([x.decodedLog.from, x.decodedLog.to], [account, zeroXProxyAddress])
  )

  // sometimes To Amount is taken from Wrapped native Token contract i.e. MATIC log #51 here https://polygonscan.com/tx/0x92acdd76597987d4f327104c0b958735a65c9c113bee244730e49fa12da8d3c0#eventlog
  // or WBTC log #104 https://polygonscan.com/tx/0x681a51746b7abb4ba08e74b7520bfc6fd83e76480121cf9aaa527c38be892a29#eventlog
  const tokenToTransfersWithApproxTokenAmount = tokenToTransfers.filter(
    (x) =>
      estimatedAmountTo &&
      valueIsInSlippageRange(
        estimatedAmountTo,
        new BigNumber(x.decodedLog.value),
        slippage,
        tipsPercent
      )
  )

  const tokenToTransferBestAmount =
    tokenToTransfersToUserWalletOrZeroX[0]?.decodedLog.value || // main suggestion
    tokenToTransfersWithApproxTokenAmount[0]?.decodedLog.value || // last hope gateway
    0 // fallback

  // value calculated based on tips % from amount that was sent payed to contract
  // needed when there is no explicit Transfer event to affiliate address i.e. 0.000013066469833045 BNB tip here https://bscscan.com/tx/0xd515ff405e06b175be3ebc5ad9baab38f05704cbaf194e1d42ead3f7cfd3cb09
  const estimatedTipsAmount = new BigNumber(tokenToTransferBestAmount).times(
    new BigNumber(tipsPercent).div(100)
  )

  // the amount user received after tip subtraction
  const toAmount = tokenToTransfersToUserWalletOrZeroX[0]?.decodedLog.value
    ? new BigNumber(tokenToTransfersToUserWalletOrZeroX[0]?.decodedLog.value)
    : getUserReceivedConsideringTips(
        tokenToTransferBestAmount,
        tipsPercent,
        transfersToAffiliateAddress[0]?.decodedLog
      )

  const tipsAmount = new BigNumber(
    transfersToAffiliateAddress[0]?.decodedLog.value || estimatedTipsAmount
  )

  return {
    fromAmount: fromAmount.div(10 ** tokenFrom.decimals),
    toAmount: toAmount.div(10 ** tokenTo.decimals),
    tipsAmount: tipsAmount.div(10 ** tokenTo.decimals), // tips are payed in token to denomination
  }
}

const readLogs = (library: Web3, encodedLog: Log, abi: AbiItem): EthLog => {
  if (!abi.inputs) {
    return {}
  }
  try {
    return library.eth.abi.decodeLog(
      abi.inputs,
      encodedLog.data,
      abi.anonymous ? encodedLog.topics : encodedLog.topics.splice(1)
    )
  } catch (e) {
    // Not the right format for this line. Let it try the others
    return {}
  }
}
